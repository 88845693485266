<template>
  <va-form
    :id="id"
    :item="item"
    disable-redirect
    @saved="handleSave()"
  >
    <!-- title_kz -->
    <va-text-input
      source="title_kz"
      v-model="clubCard.title_kz"
      required
    ></va-text-input>

    <!-- title_ru -->
    <va-text-input
      source="title_ru"
      v-model="clubCard.title_ru"
      required
    ></va-text-input>

    <!-- description_kz -->
    <va-text-input
      source="description_kz"
      v-model="clubCard.description_kz"
      required
    ></va-text-input>

    <!-- description_ru -->
    <va-text-input
      source="description_ru"
      v-model="clubCard.description_ru"
      required
    ></va-text-input>

    <!-- is_temporary -->
    <va-boolean-input
      source="is_temporary"
      v-model="getIsTemporary"
    ></va-boolean-input>

    <!-- start_date -->
    <va-date-input
      v-if="clubCard.is_temporary"
      v-model="getStartDate"
      source="start_date"
      format="short"
    ></va-date-input>

    <!-- end_date -->
    <va-date-input
      v-if="clubCard.is_temporary"
      v-model="getEndDate"
      source="end_date"
      format="short"
    ></va-date-input>

    <!-- img -->
    <div
      v-if="!rawImage"
      class="edit-image-wrapper"
    >
      <img :src="getImage" />
    </div>
    <va-file-input
      source="img"
      clearable
      preview
      v-model="rawImage"
      accept="image/*"
    ></va-file-input>
    <va-save-button></va-save-button>
  </va-form>
</template>

<script>
import http from '../../plugins/axios';
import imageEncoder from '../../helpers/imageEncoder';
import millisecondsToDate, { dateToMilliseconds } from '../../helpers/dateConverter';

export default {
  props: ['id', 'item'],

  data() {
    return {
      clubCard: {},
      rawImage: null,
    };
  },

  computed: {
    getImage() {
      return this.clubCard?.img;
    },
    getIsTemporary: {
      get: function () {
        return Boolean(this.clubCard?.is_temporary);
      },
      set: function (newValue) {
        this.clubCard.is_temporary = newValue;
      },
    },
    getStartDate: {
      get: function () {
        return this.clubCard.start_date
          ? dateToMilliseconds(this.clubCard.start_date)
          : Date.now();
      },
      set: function (newValue) {
        this.clubCard.start_date = millisecondsToDate(newValue);
      },
    },
    getEndDate: {
      get: function () {
        return this.clubCard.end_date
          ? dateToMilliseconds(this.clubCard.end_date)
          : Date.now();
      },
      set: function (newValue) {
        this.clubCard.end_date = millisecondsToDate(newValue);
      },
    },
  },

  watch: {
    id() {
      this.loadClubCard();
    },
  },

  async created() {
    this.loadClubCard();
  },

  methods: {
    async loadClubCard() {
      const response = await http.get(`club-cards/${this.id}`);
      this.clubCard = response.data.data;
    },

    async handleSave() {
      if (this.rawImage) {
        this.clubCard.img = await imageEncoder(this.rawImage);
      } else {
        delete this.clubCard['img'];
      }

      if (!this.clubCard.is_temporary) {
        this.clubCard.start_date = null;
        this.clubCard.end_date = null;
      }

      const updatedData = this.clubCard;

      this.$admin.http.put(`/club-cards/${this.id}`, updatedData).then(() => {
        this.$emit('saved');
      });
    },
  },
};
</script>
