<template>
  <va-form
    :id="id"
    :item="item"
    disable-redirect
    @saved="$emit('saved')"
  >
    <va-text-input
      source="name"
      required
    ></va-text-input>
    <va-save-button></va-save-button>
  </va-form>
</template>

<script>
export default {
  props: ['id', 'item'],
};
</script>
