<template>
  <va-show :item="item">
    <va-text-field source="title_kz"></va-text-field>
    <va-text-field source="title_ru"></va-text-field>
    <va-text-field source="description_kz"></va-text-field>
    <va-text-field source="description_ru"></va-text-field>
    <va-boolean-field
      source="is_temporary"
      icon-false="mdi-cancel"
    ></va-boolean-field>
    <va-date-field
      source="start_date"
      format="short"
    ></va-date-field>
    <va-date-field
      source="end_date"
      format="short"
    ></va-date-field>
  </va-show>
</template>

<script>
export default {
  props: ['item'],
};
</script>
