<template>
  <div class="hot-import">
    <v-file-input
      v-model="file"
      :label="$t('forms.file')"
      accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
      :prepend-icon="null"
      :messages="responseMessage"
      :error="isError"
      filled
      @change="handleInputFile"
    ></v-file-input>
    <v-btn
      color="primary"
      @click="handleSubmit"
    >
      <v-icon left> mdi-floppy </v-icon>
      Save
    </v-btn>
  </div>
</template>

<script>
import api from '../../services/api';

export default {
  data() {
    return {
      file: null,
      responseMessage: null,
      isError: false,
    };
  },

  methods: {
    handleInputFile() {
      this.responseMessage = null;
      this.isError = false;
    },
    async handleSubmit() {
      if (!this.file) return;

      try {
        const response = await api.importHotFile(this.file);
        this.responseMessage = response.message;
        this.file = null;
      } catch (error) {
        this.isError = true;
        this.responseMessage = error.response.data.message;
      }
    },
  },
};
</script>

<style
  lang="scss"
  scoped
>
.hot-import {
  &__message {
    display: block;
  }
}
</style>
