<template>
  <va-form
    :id="id"
    :item="item"
    disable-redirect
    @saved="handleSave()"
  >
    <!-- section_id -->
    <va-number-input
      source="section_id"
      v-model="upperBannerData.section_id"
      required
    ></va-number-input>

    <!-- link -->
    <va-text-input
      source="link"
      v-model="upperBannerData.link"
      required
    ></va-text-input>

    <!-- img_desktop_kz -->
    <div
      v-if="!desktopKzImage"
      class="edit-image-wrapper"
    >
      <img :src="getDesktopKzImage" />
    </div>
    <va-file-input
      source="img_desktop_kz"
      clearable
      preview
      v-model="desktopKzImage"
      accept="image/*"
    ></va-file-input>

    <!-- img_desktop_ru -->
    <div
      v-if="!desktopRuImage"
      class="edit-image-wrapper"
    >
      <img :src="getDesktopRuImage" />
    </div>
    <va-file-input
      source="img_desktop_ru"
      clearable
      preview
      v-model="desktopRuImage"
      accept="image/*"
    ></va-file-input>

    <!-- img_mobile_kz -->
    <div
      v-if="!mobileKzImage"
      class="edit-image-wrapper"
    >
      <img :src="getMobileKzImage" />
    </div>
    <va-file-input
      source="img_mobile_kz"
      clearable
      preview
      v-model="mobileKzImage"
      accept="image/*"
    ></va-file-input>

    <!-- img_mobile_ru -->
    <div
      v-if="!mobileRuImage"
      class="edit-image-wrapper"
    >
      <img :src="getMobileRuImage" />
    </div>
    <va-file-input
      source="img_mobile_ru"
      clearable
      preview
      v-model="mobileRuImage"
      accept="image/*"
    ></va-file-input>

    <va-save-button></va-save-button>
  </va-form>
</template>

<script>
import http from '../../plugins/axios';
import imageEncoder from '../../helpers/imageEncoder';

export default {
  props: ['id', 'item'],

  data() {
    return {
      upperBannerData: {},
      desktopKzImage: null,
      desktopRuImage: null,
      mobileKzImage: null,
      mobileRuImage: null,
    };
  },

  computed: {
    getDesktopKzImage() {
      return this.upperBannerData?.img_desktop_kz;
    },
    getDesktopRuImage() {
      return this.upperBannerData?.img_desktop_ru;
    },
    getMobileKzImage() {
      return this.upperBannerData?.img_mobile_kz;
    },
    getMobileRuImage() {
      return this.upperBannerData?.img_mobile_ru;
    },
  },

  watch: {
    id() {
      this.loadUpperBannerData();
    },
  },

  async created() {
    this.loadUpperBannerData();
  },

  methods: {
    async loadUpperBannerData() {
      const response = await http.get(`upper-banners/${this.id}`);
      this.upperBannerData = response.data.data;
    },

    async handleSave() {
      // img_desktop_kz
      if (this.desktopKzImage) {
        this.upperBannerData.img_desktop_kz = await imageEncoder(this.desktopKzImage);
      } else {
        delete this.upperBannerData['img_desktop_kz'];
      }

      // img_desktop_ru
      if (this.desktopRuImage) {
        this.upperBannerData.img_desktop_ru = await imageEncoder(this.desktopRuImage);
      } else {
        delete this.upperBannerData['img_desktop_ru'];
      }

      // img_mobile_kz
      if (this.mobileKzImage) {
        this.upperBannerData.img_mobile_kz = await imageEncoder(this.mobileKzImage);
      } else {
        delete this.upperBannerData['img_mobile_kz'];
      }

      // img_mobile_ru
      if (this.mobileRuImage) {
        this.upperBannerData.img_mobile_ru = await imageEncoder(this.mobileRuImage);
      } else {
        delete this.upperBannerData['img_mobile_ru'];
      }

      const updatedData = this.upperBannerData;

      this.$admin.http.put(`/upper-banners/${this.id}`, updatedData).then(() => {
        this.$emit('saved');
      });
    },
  },
};
</script>
