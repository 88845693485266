import axios from 'axios';

//urls
const baseURL = process.env.VUE_APP_BASE_API_URL || 'https://alux.skiftrade.kz/api';
const integratedURL = process.env.VUE_APP_INTEGRATED_API_URL || 'https://small.kz/api';

//options
const token = localStorage.getItem('token');
const headers = {
  'X-Requested-With': 'XMLHttpRequest',
  Accept: 'applicatoin/json',
};

if (token) {
  headers['Authorization'] = `Bearer ${token}`;
}

// instances
const productionInstance = axios.create({
  baseURL,
  headers,
});

const integratedInstance = axios.create({
  baseURL: integratedURL,
  headers,
});

// interceptors
productionInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response.status === 401) {
      // localStorage.removeItem('token');
    }
    throw error;
  }
);

export { integratedInstance };
export default productionInstance;
