import productionInstance, { integratedInstance } from '../plugins/axios';

const enpoints = {
  cities: '/catalogs/cities',
  shops: '/clientApp/shops',
  notifications: '/notifications',
  notificationById: (id) => `/notification/${id}`,
  statistics: '/statistics',
  hot: '/admin/hot',
  hotById: (id) => `/admin/hot/${id}`,
  importHot: '/admin/hot/import',
};

const api = {
  // cities
  async loadAllCities() {
    const response = await productionInstance.get(enpoints.cities);
    return response.data;
  },

  // shops
  async loadAllShops() {
    const response = await integratedInstance.get(enpoints.shops);
    return response.data;
  },

  // notifications
  async loadNotifications() {
    const response = await productionInstance.get(enpoints.notifications);
    return response.data;
  },
  async createNotification(data) {
    const response = await productionInstance.post(enpoints.notifications, data);
    return response.data;
  },
  async deleteNotification({ id }) {
    const response = await productionInstance.delete(enpoints.notificationById(id));
    return response.data;
  },

  // statistics
  async loadStatistics() {
    const response = await productionInstance.get(enpoints.statistics);
    return response.data;
  },

  // hot
  async loadHot() {
    const response = await productionInstance.get(enpoints.hot);
    return response.data;
  },
  async loadHotByShopId({ id }) {
    const response = await productionInstance.get(enpoints.hotById(id));
    return response.data;
  },
  async createHot(data) {
    const response = await productionInstance.post(enpoints.hot, data);
    return response.data;
  },
  async deleteHot(data) {
    const response = await productionInstance.delete(enpoints.hot, data);
    return response.data;
  },
  async importHotFile(data) {
    const formData = new FormData();
    formData.append('file', data);
    const response = await productionInstance.post(enpoints.importHot, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    return response.data;
  },
};

export default api;
