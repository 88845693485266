<template>
  <va-show :item="item">
    <va-field source="id"> </va-field>
    <va-field source="title"></va-field>
    <va-field source="text"></va-field>
    <va-field source="created_at"></va-field>
    <va-date-field source="updated_at" format="short"></va-date-field>
  </va-show>
</template>

<script>
export default {
  props: ['item'],
};
</script>
