<template>
  <div>
    <!-- show -->
    <va-aside-layout :title="asideTitle">
      <upper-banners-show
        v-if="show"
        :item="item"
      ></upper-banners-show>

      <!-- edit -->
      <upper-banners-edit
        v-if="edit"
        :id="id"
        :item="item"
        @saved="onSaved"
      ></upper-banners-edit>

      <!-- create -->
      <upper-banners-form
        v-else
        :id="id"
        :item="item"
        @saved="onSaved"
      ></upper-banners-form>
    </va-aside-layout>

    <!-- list -->
    <base-material-card
      :icon="resource.icon"
      :title="title"
      color="indigo"
    >
      <va-list
        ref="bannersList"
        disable-create-redirect
        disable-global-search
        @action="onAction"
      >
        <va-data-table
          :fields="fields"
          disable-edit-redirect
          @item-action="onAction"
        >
          <template v-slot:[`field.img_desktop_kz`]="{ value }">
            <div class="table-image-wrapper">
              <img
                :src="value"
                alt=" image"
              />
            </div>
          </template>
          <template v-slot:[`field.img_desktop_ru`]="{ value }">
            <div class="table-image-wrapper">
              <img
                :src="value"
                alt=" image"
              />
            </div>
          </template>
          <template v-slot:[`field.img_mobile_kz`]="{ value }">
            <div class="table-image-wrapper">
              <img
                :src="value"
                alt=" image"
              />
            </div>
          </template>
          <template v-slot:[`field.img_mobile_ru`]="{ value }">
            <div class="table-image-wrapper">
              <img
                :src="value"
                alt=" image"
              />
            </div>
          </template>
        </va-data-table>
      </va-list>
    </base-material-card>
  </div>
</template>

<script>
export default {
  components: {},
  props: ['resource', 'title'],
  data() {
    return {
      fields: ['img_desktop_kz', 'img_desktop_ru', 'img_mobile_kz', 'img_mobile_ru'],
      asideTitle: null,
      id: null,
      item: null,
      show: false,
      edit: false,
    };
  },
  methods: {
    async onAction({ action, title, id, item }) {
      this.asideTitle = title;
      this.id = id;
      this.show = action === 'show';
      this.edit = action === 'edit';
      this.item = item;

      if (action === 'create') {
        this.item = null;
        this.id = null;
      }
    },
    onSaved() {
      this.asideTitle = null;
      this.$refs.bannersList.fetchData();
    },
  },
  mounted() {},
};
</script>

<style
  lang="sass"
  scoped
></style>
