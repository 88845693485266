<template>
  <v-row class="breadcrumbs-wrapper" align="center">
    <v-col cols="12" sm="8" lg="9">
      <va-breadcrumbs color="#fafafa"></va-breadcrumbs>
    </v-col>
    <!-- <v-col cols="12" sm="4" lg="3">
      <v-select
        @change="handleLocaleChange"
        class="breadcrumbs__select"
        :value="getCurrentLocale"
        :items="locales"
        menu-props="auto"
        :label="$t('interface.language')"
        hide-details
        prepend-icon="mdi-translate"
        dense
        outlined
      ></v-select>
    </v-col> -->
  </v-row>
</template>

<script>
export default {
  name: 'Breadcrumbs',
  data() {
    return {
      currentLocale: null,
      locales: ['ru', 'en'],
    };
  },
  computed: {
    getCurrentLocale() {
      return this.currentLocale || this.$i18n.locale;
    },
  },
  methods: {
    handleLocaleChange(value) {
      this.currentLocale = value;
      this.$i18n.locale = value;
      localStorage.setItem('locale', value);
      this.$router.go();
    },
  },
};
</script>

<style lang="sass" scoped>
.breadcrumbs
  &-wrapper
    flex: none !important
    background: #fff
  &__select
    padding: 18px 12px
</style>
