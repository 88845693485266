var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('va-aside-layout',{attrs:{"title":_vm.asideTitle}},[(_vm.show)?_c('upper-banners-show',{attrs:{"item":_vm.item}}):_vm._e(),(_vm.edit)?_c('upper-banners-edit',{attrs:{"id":_vm.id,"item":_vm.item},on:{"saved":_vm.onSaved}}):_c('upper-banners-form',{attrs:{"id":_vm.id,"item":_vm.item},on:{"saved":_vm.onSaved}})],1),_c('base-material-card',{attrs:{"icon":_vm.resource.icon,"title":_vm.title,"color":"indigo"}},[_c('va-list',{ref:"bannersList",attrs:{"disable-create-redirect":"","disable-global-search":""},on:{"action":_vm.onAction}},[_c('va-data-table',{attrs:{"fields":_vm.fields,"disable-edit-redirect":""},on:{"item-action":_vm.onAction},scopedSlots:_vm._u([{key:"field.img_desktop_kz",fn:function(ref){
var value = ref.value;
return [_c('div',{staticClass:"table-image-wrapper"},[_c('img',{attrs:{"src":value,"alt":" image"}})])]}},{key:"field.img_desktop_ru",fn:function(ref){
var value = ref.value;
return [_c('div',{staticClass:"table-image-wrapper"},[_c('img',{attrs:{"src":value,"alt":" image"}})])]}},{key:"field.img_mobile_kz",fn:function(ref){
var value = ref.value;
return [_c('div',{staticClass:"table-image-wrapper"},[_c('img',{attrs:{"src":value,"alt":" image"}})])]}},{key:"field.img_mobile_ru",fn:function(ref){
var value = ref.value;
return [_c('div',{staticClass:"table-image-wrapper"},[_c('img',{attrs:{"src":value,"alt":" image"}})])]}}],null,true)})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }