const millisecondsToDate = (milliseconds) => {
  const fullDate = new Date(milliseconds);

  const day = fullDate.getDate();
  const month = fullDate.getMonth() + 1;
  const year = fullDate.getFullYear();

  return `${year}-${month}-${day}`;
};

export const dateToMilliseconds = (date) => {
  let dateParts;
  const dateFormat = new Date(); // some mock date

  if (date.includes('-')) {
    dateParts = date.split('-');

    dateFormat.setFullYear(dateParts[0]);
    dateFormat.setMonth(dateParts[1] - 1);
    dateFormat.setDate(dateParts[2]);
  } else {
    dateParts = date.split('.');
    console.log(dateParts)
    dateFormat.setFullYear(dateParts[2]);
    dateFormat.setMonth(dateParts[1] - 1);
    dateFormat.setDate(dateParts[0]);
  }

  const milliseconds = dateFormat.getTime();

  return milliseconds;
};

export default millisecondsToDate;
