const readUploadedFileAsText = (inputFile) => {
  const reader = new FileReader();

  return new Promise((resolve, reject) => {
    reader.onerror = function () {
      reader.abort();
      reject(new DOMException('Problem parsing input file.'));
    };

    reader.onloadend = function () {
      resolve(reader.result);
    };

    reader.readAsDataURL(inputFile);
  });
};

const imageEncoder = async (file) => {
  try {
    const fileData = await readUploadedFileAsText(file);

    return fileData;
  } catch (e) {
    console.warn(e.message);
  }
};

export default imageEncoder;
