import Vue from 'vue';
import upperFirst from 'lodash/upperFirst';
import camelCase from 'lodash/camelCase';

const requireComponent = require.context('@/components/base', true, /\.vue$/);
const requireCustomComponent = require.context('@/components/custom', true, /\.vue$/);

requireComponent.keys().forEach((fileName) => {
  const componentConfig = requireComponent(fileName);

  const componentName = upperFirst(camelCase(fileName.replace(/^\.\//, '').replace(/\.\w+$/, '')));

  Vue.component(`Base${componentName}`, componentConfig.default || componentConfig);
});

requireCustomComponent.keys().forEach((fileName) => {
  const componentConfig = requireCustomComponent(fileName);

  const componentName = upperFirst(camelCase(fileName.replace(/^\.\//, '').replace(/\.\w+$/, '')));

  Vue.component(`Custom${componentName}`, componentConfig.default || componentConfig);
});
