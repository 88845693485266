<template>
  <va-action-button
    :label="$t('actions.import')"
    icon="mdi-publish"
    color="success"
    :action="{ active: false }"
    @click="handleClick"
    text
  ></va-action-button>
</template>

<script>
export default {
  name: 'ImportButton',
  data() {
    return {};
  },
  methods: {
    handleClick() {
      this.$emit('action', { action: 'import', title: this.$t('actions.import') });
    },
  },
};
</script>
