<template>
  <va-form
    :id="id"
    :item="item"
    disable-redirect
    @saved="$emit('saved')"
  >
    <va-number-input source="barcode"></va-number-input>
    <va-select-input
      :label="$t('interface.city')"
      :choices="selectCities"
      @input="handleCityChange"
    ></va-select-input>
    <va-select-input
      :label="$t('interface.shop')"
      source="shop_id"
      :choices="selectShops"
    ></va-select-input>
    <va-save-button></va-save-button>
  </va-form>
</template>

<script>
export default {
  props: ['id', 'item', 'cities'],
  data() {
    return {
      selectShops: [],
    };
  },
  computed: {
    selectCities() {
      const citiesArray = this.cities.map((item) => ({
        value: item.id,
        text: item.title,
      }));

      return citiesArray;
    },
  },
  methods: {
    async handleCityChange(value) {
      const city = this.cities.find((item) => item.id === value);
      if (city) {
        this.selectShops = city.shops.map((item) => ({
          value: item.shop_id,
          text: item.name,
        }));
      }
    },
  },
};
</script>
