<template>
  <div>
    <va-aside-layout :title="asideTitle">
      <!-- show -->
      <bonus-coupons-show
        v-if="show"
        :item="item"
      ></bonus-coupons-show>

      <!-- edit -->
      <bonus-coupons-edit
        v-if="edit"
        :id="id"
        :item="item"
        @saved="onSaved"
      ></bonus-coupons-edit>

      <!-- create -->
      <bonus-coupons-form
        v-else
        :id="id"
        :item="item"
        @saved="onSaved"
      ></bonus-coupons-form>
    </va-aside-layout>

    <!-- list -->
    <base-material-card
      :icon="resource.icon"
      :title="title"
      color="purple"
    >
      <va-list
        ref="bonusCouponsList"
        disable-create-redirect
        disable-global-search
        @action="onAction"
      >
        <va-data-table
          :fields="fields"
          disable-edit-redirect
          @item-action="onAction"
        >
          <!-- disable-show-redirect -->
          <template v-slot:[`field.img`]="{ value }">
            <div class="table-image-wrapper">
              <img
                :src="value"
                alt=" image"
              />
            </div>
          </template>
        </va-data-table>
      </va-list>
    </base-material-card>
  </div>
</template>

<script>
export default {
  components: {},
  props: ['resource', 'title'],
  data() {
    return {
      fields: [
        'img',
        { source: 'title_kz', sortable: true },
        { source: 'title_ru', sortable: true },
        { source: 'description_kz' },
        { source: 'description_ru' },
        { source: 'sale', sortable: false },
        { source: 'price', sortable: true },
        {
          source: 'expires_at',
          type: 'date',
          sortable: true,
        },
      ],
      asideTitle: null,
      id: null,
      item: null,
      show: false,
      edit: false,
    };
  },
  methods: {
    async onAction({ action, title, id, item }) {
      this.asideTitle = title;
      this.id = id;
      this.show = action === 'show';
      this.edit = action === 'edit';
      this.item = item;

      if (action === 'create') {
        this.item = null;
        this.id = null;
      }
    },
    onSaved() {
      this.asideTitle = null;
      this.$refs.bonusCouponsList.fetchData();
    },
  },
  mounted() {},
};
</script>

<style
  lang="sass"
  scoped
></style>
