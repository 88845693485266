<template>
  <div>
    <va-aside-layout :title="asideTitle">
      <notifications-show
        v-if="show"
        :item="item"
      ></notifications-show>

      <notifications-form
        v-else
        :id="id"
        :item="item"
        @saved="onSaved"
      ></notifications-form>
    </va-aside-layout>
    
    <base-material-card
      :icon="resource.icon"
      :title="title"
      color="success"
    >
      <va-list
        ref="notificationsList"
        disable-create-redirect
        disable-global-search
        @action="onAction"
      >
        <va-data-table
          :fields="fields"
          @item-action="onAction"
        >
        </va-data-table>
      </va-list>
    </base-material-card>
  </div>
</template>

<script>
export default {
  components: {},
  props: ['resource', 'title'],
  data() {
    return {
      fields: [
        { source: 'id', sortable: true },
        { source: 'title', sortable: false },
        { source: 'text', sortable: false },
      ],
      asideTitle: null,
      id: null,
      item: null,
      show: false,
    };
  },
  methods: {
    async onAction({ action, title, id, item }) {
      this.asideTitle = title;
      this.id = id;
      this.show = action === 'show';
      this.item = item;
    },
    onSaved() {
      this.asideTitle = null;
      this.$refs.notificationsList.fetchData();
    },
  },
};
</script>
