/* eslint-disable no-unused-vars */
import api from '../services/api';
import {
  GET_LIST,
  GET_MANY,
  GET_ONE,
  CREATE,
  UPDATE,
  UPDATE_MANY,
  DELETE,
  DELETE_MANY,
} from 'vuetify-admin/src/providers/data/actions';
import imageEncoder from '../helpers/imageEncoder';
import millisecondsToDate from '../helpers/dateConverter';

export default (httpClient) => {
  return {
    [GET_LIST]: async (resource, params) => {
      try {
        switch (resource) {
          case 'hot': {
            let response;
            const action = JSON.parse(localStorage.getItem('hot'));
            if (action !== null && action.type === 'fetch') {
              response = await api.loadHotByShopId({ id: action.payload.shopId });
            } else response = await api.loadHot();
            // not my fault, backender was dumb
            response.data = response.data.map((item, index) => {
              item.id = `${index}-${item.barcode}`;
              return item;
            });

            return { data: response.data, total: response.data.length };
          }
          case 'sections': {
            const response = await httpClient.get(resource);
            const data = response.data.message.data;
            return { data, total: data.length };
          }
          default: {
            const response = await httpClient.get(resource);
            const data = response.data.data;
            return { data, total: data.length };
          }
        }
      } catch (error) {
        console.log(error);
        return { data: [] };
      }
    },
    [GET_MANY]: (resource, params) => ({}),
    [GET_ONE]: (resource, params) => {
      return { data: {} };
    },
    [CREATE]: async (resource, params) => {
      const handleSendData = async (resource, paramsData) => {
        const response = await httpClient.post(resource, paramsData);
        const data = response.data.data;
        return { data };
      };

      switch (resource) {
        case 'hot': {
          const response = await api.createHot(params.data);
          return { data: response.data };
        }
        case 'bonus-coupons': {
          params.data.expires_at = millisecondsToDate(params.data.expires_at);
          params.data.img = await imageEncoder(params.data.img);
          return handleSendData(resource, params.data);
        }
        case 'birthday-coupons': {
          params.data.img = await imageEncoder(params.data.img);
          return handleSendData(resource, params.data);
        }
        case 'club-cards': {
          if (params.data.is_temporary) {
            params.data.start_date = millisecondsToDate(params.data.start_date);
            params.data.end_date = millisecondsToDate(params.data.end_date);
          } else {
            params.data.start_date = null;
            params.data.end_date = null;
          }
          params.data.img = await imageEncoder(params.data.img);
          return handleSendData(resource, params.data);
        }
        case 'upper-banners': {
          params.data.img_desktop_ru = await imageEncoder(params.data.img_desktop_ru);
          params.data.img_desktop_kz = await imageEncoder(params.data.img_desktop_kz);
          params.data.img_mobile_ru = await imageEncoder(params.data.img_mobile_ru);
          params.data.img_mobile_kz = await imageEncoder(params.data.img_mobile_kz);
          return handleSendData(resource, params.data);
        }
        default: {
          return handleSendData(resource, params.data);
        }
      }
    },
    [UPDATE]: async (resource, params) => {
      return Promise.resolve({ data: {} });
    },
    [UPDATE_MANY]: (resource, params) =>
      Promise.all(params.ids.map((id) => Promise.resolve())).then(() =>
        Promise.resolve()
      ),
    [DELETE]: async (resource, params) => {
      try {
        switch (resource) {
          case 'hot':
            console.log(params);
            // await httpClient.delete(`admin/${resource}/${params.id}`);
            break;
          default:
            await httpClient.delete(`${resource}/${params.id}`);
            break;
        }
        return Promise.resolve();
      } catch (error) {
        return Promise.reject(error);
      }
    },
    [DELETE_MANY]: (resource, params) =>
      Promise.all(
        params.ids.map((id) => {
          try {
            httpClient.delete(`${resource}/${id}`).then((response) => Promise.resolve());
          } catch (error) {
            return Promise.reject(error);
          }
        })
      ),
  };
};
