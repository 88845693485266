export default [
  {
    name: 'statistics',
    icon: 'mdi-file-document',
    routes: ['list'],
    translatable: true,
  },
  {
    name: 'notifications',
    icon: 'mdi-bell',
    routes: ['list'],
    translatable: true,
  },
  {
    name: 'hot',
    icon: 'mdi-fire',
    routes: ['list'],
  },
  {
    name: 'sections',
    icon: 'mdi-view-grid-plus',
    routes: ['list'],
  },
  {
    name: 'user',
    icon: 'mdi-account-group',
    routes: ['list'],
  },
  {
    name: 'club-cards',
    icon: 'mdi-card-text',
    routes: ['list'],
  },
  {
    name: 'bonus-coupons',
    icon: 'mdi-ticket-percent',
    routes: ['list'],
  },
  {
    name: 'upper-banners',
    icon: 'mdi-newspaper-variant',
    routes: ['list'],
  },
  {
    name: 'birthday-coupons',
    icon: 'mdi-cake',
    routes: ['list'],
  },
];
