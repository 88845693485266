/* eslint-disable no-unused-vars */

export default (i18n, admin) => [
  admin.getResourceLink('statistics'),
  { divider: true },
  admin.getResourceLink('notifications'),
  { divider: true },
  admin.getResourceLink('hot'),
  { divider: true },
  admin.getResourceLink('sections'),
  { divider: true },
  admin.getResourceLink('user'),
  { divider: true },
  admin.getResourceLink('club-cards'),
  { divider: true },
  admin.getResourceLink('bonus-coupons'),
  { divider: true },
  admin.getResourceLink('upper-banners'),
  { divider: true },
  admin.getResourceLink('birthday-coupons'),
];
