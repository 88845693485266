import Vue from 'vue';
import VuetifyAdmin from 'vuetify-admin';

import 'vuetify-admin/src/loader';

import dataProvider from '@/providers/dataProvider';
import authProvider from '@/providers/authProvider';

import {} from 'vuetify-admin/src/providers';
// import { en } from 'vuetify-admin/src/locales';
import { ru, en } from '../locales';

import router from '@/router';
import routes from '@/router/admin';
import store from '@/store';
import i18n from '@/i18n';
import resources from '@/resources';
import http from './axios';

Vue.use(VuetifyAdmin);

export default new VuetifyAdmin({
  router,
  store,
  i18n,
  title: 'Small Admin',
  routes,
  locales: {
    en,
    ru,
  },
  translations: ['en', 'ru'],
  dataProvider: dataProvider(http),
  authProvider: authProvider(http),
  resources,
  http,
  options: {
    dateFormat: 'long',
  },
});
