<template>
  <va-form
    :id="id"
    :item="item"
    disable-redirect
    @saved="handleSave()"
  >
    <!-- title_kz -->
    <va-text-input
      source="title_kz"
      v-model="birthdayCouponsData.title_kz"
      required
    ></va-text-input>

    <!-- title_ru -->
    <va-text-input
      source="title_ru"
      v-model="birthdayCouponsData.title_ru"
      required
    ></va-text-input>

    <!-- description_kz -->
    <va-text-input
      source="description_kz"
      v-model="birthdayCouponsData.description_kz"
      required
    ></va-text-input>

    <!-- description_ru -->
    <va-text-input
      source="description_ru"
      v-model="birthdayCouponsData.description_ru"
      required
    ></va-text-input>

    <!-- expires_at -->
    <va-date-input
      v-if="birthdayCouponsData.expires_at"
      v-model="getExpiresDate"
      source="expires_at"
      format="short"
    ></va-date-input>

    <!-- img -->
    <div
      v-if="!rawImage"
      class="edit-image-wrapper"
    >
      <img :src="getImage" />
    </div>
    <va-file-input
      source="img"
      clearable
      preview
      v-model="rawImage"
      accept="image/*"
    ></va-file-input>
    <va-save-button></va-save-button>
  </va-form>
</template>

<script>
import http from '../../plugins/axios';
import imageEncoder from '../../helpers/imageEncoder';
import millisecondsToDate, { dateToMilliseconds } from '../../helpers/dateConverter';

export default {
  props: ['id', 'item'],

  data() {
    return {
      birthdayCouponsData: {},
      rawImage: null,
    };
  },

  computed: {
    getImage() {
      return this.birthdayCouponsData?.img;
    },
    getExpiresDate: {
      get: function () {
        return this.birthdayCouponsData.expires_at
          ? dateToMilliseconds(this.birthdayCouponsData.expires_at)
          : Date.now();
      },
      set: function (newValue) {
        this.birthdayCouponsData.expires_at = millisecondsToDate(newValue);
      },
    },
  },

  watch: {
    id() {
      this.loadBirthdayCouponsData();
    },
  },

  async created() {
    this.loadBirthdayCouponsData();
  },

  methods: {
    async loadBirthdayCouponsData() {
      const response = await http.get(`/birthday-coupons/${this.id}`);
      this.birthdayCouponsData = response.data.data;
    },

    async handleSave() {
      if (this.rawImage) {
        this.birthdayCouponsData.img = await imageEncoder(this.rawImage);
      } else {
        delete this.birthdayCouponsData['img'];
      }

      const updatedData = this.birthdayCouponsData;

      this.$admin.http.put(`/birthday-coupons/${this.id}`, updatedData).then(() => {
        this.$emit('saved');
      });
    },
  },
};
</script>
