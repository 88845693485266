<template>
  <div>
    <va-aside-layout :title="asideTitle">
      <statistics-show
        v-if="show"
        :item="item"
      ></statistics-show>

      <statistics-form
        v-else
        :id="id"
        :item="item"
        @saved="onSaved"
      ></statistics-form>
    </va-aside-layout>
    
    <base-material-card
      disable-global-search
      :icon="resource.icon"
      :title="title"
      color="primary"
    >
      <va-list
        ref="statisticsList"
        @action="onAction"
      >
        <va-data-table
          :fields="fields"
          disableDelete
          disableSelect
          @item-action="onAction"
        ></va-data-table>
      </va-list>
    </base-material-card>
  </div>
</template>

<script>
export default {
  components: {},
  props: ['resource', 'title'],
  data() {
    return {
      fields: [
        { source: 'name', sortable: true },
        { source: 'barcode', sortable: false },
        { source: 'count', sortable: true },
      ],
      asideTitle: null,
      id: null,
      item: null,
      show: false,
    };
  },
  methods: {
    async onAction({ action, title, id, item }) {
      this.asideTitle = title;
      this.id = id;
      this.show = action === 'show';
      this.item = item;
    },
    onSaved() {
      this.asideTitle = null;
      this.$refs.statisticsList.fetchData();
    },
  },
  mounted() {},
};
</script>

<style
  lang="sass"
  scoped
>
.item-actions
  text-align: end !important
</style>
