<template>
  <div>
    <va-aside-layout :title="asideTitle">
      <!-- show -->
      <user-show
        v-if="show"
        :item="item"
      ></user-show>

      <!-- edit -->
      <user-edit
        v-if="edit"
        :id="id"
        :item="item"
        @saved="onSaved"
      ></user-edit>
    </va-aside-layout>

    <!-- list -->
    <base-material-card
      :icon="resource.icon"
      :title="title"
      color="teal"
    >
      <va-list
        ref="userList"
        disable-global-search
        @action="onAction"
      >
        <va-data-table
          :fields="fields"
          disable-edit-redirect
          @item-action="onAction"
        >
          <template v-slot:[`field.gender`]="{ value }">
            <div
              v-if="getGender(value)"
              class="gender-wrapper"
            >
              <p>{{ getGender(value) }}</p>
            </div>
          </template>
        </va-data-table>
      </va-list>
    </base-material-card>
  </div>
</template>

<script>
export default {
  components: {},
  props: ['resource', 'title'],
  data() {
    return {
      fields: [
        { source: 'id', sortable: true },
        { source: 'name', sortable: true },
        { source: 'surname', sortable: true },
        { source: 'email', sortable: false },
        { source: 'phone', sortable: false },
        { source: 'card_number', sortable: false },
        { source: 'gender', sortable: false },
        {
          source: 'birth_date',
          type: 'date',
          sortable: true,
        },
        { source: 'is_notifications_enabled', type: 'boolean', editable: false },
        {
          source: 'created_at',
          type: 'date',
          sortable: true,
        },
      ],
      asideTitle: null,
      id: null,
      item: null,
      show: false,
      edit: false,
    };
  },
  methods: {
    async onAction({ action, title, id, item }) {
      this.asideTitle = title;
      this.id = id;
      this.show = action === 'show';
      this.edit = action === 'edit';
      this.item = item;
    },
    onSaved() {
      this.asideTitle = null;
      this.$refs.userList.fetchData();
    },
    getGender(type) {
      switch (type) {
        case 0:
          return 'Женщина';
        case 1:
          return 'Мужчина';
        default:
          return '';
      }
    },
  },
  mounted() {},
};
</script>

<style
  lang="sass"
  scoped
></style>
