<template>
  <va-form
    :id="id"
    :item="item"
    disable-redirect
    @saved="$emit('saved')"
  >
    <va-text-input source="link"></va-text-input>
    <va-number-input source="section_id"></va-number-input>
    <va-file-input
      source="img_desktop_kz"
      clearable
      preview
    ></va-file-input>
    <va-file-input
      source="img_desktop_ru"
      clearable
      preview
    ></va-file-input>
    <va-file-input
      source="img_mobile_kz"
      clearable
      preview
    ></va-file-input>
    <va-file-input
      source="img_mobile_ru"
      clearable
      preview
    ></va-file-input>
    <va-save-button></va-save-button>
  </va-form>
</template>

<script>
export default {
  props: ['id', 'item'],
};
</script>
