import api from '../services/api';

const moduleType = 'APP';

const state = {
  cities: [],
  shops: [],
};

const getters = {};

const mutationTypes = {
  SET_CITIES: `${moduleType}/LOAD_CITIES`,
  SET_SHOPS: `${moduleType}/LOAD_SHOPS`,
};

export const actionTypes = {
  LOAD_CITIES: `${moduleType}/LOAD_CITIES`,
  LOAD_SHOPS: `${moduleType}/LOAD_SHOPS`,
};

const mutations = {
  [mutationTypes.SET_CITIES](state, payload) {
    state.cities = payload.cities;
  },
  [mutationTypes.SET_SHOPS](state, payload) {
    state.shops = payload.shops;
  },
};

const actions = {
  async [actionTypes.LOAD_CITIES]({ commit }) {
    const response = await api.loadAllCities();
    const cities = response.data;
    commit({ type: mutationTypes.SET_CITIES, cities });
  },
  async [actionTypes.LOAD_SHOPS]({ commit }) {
    const response = await api.loadAllShops();
    const shops = response.data;
    commit({ type: mutationTypes.SET_SHOPS, shops });
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
