<template>
  <va-form
    :id="id"
    :item="item"
    disable-redirect
    @saved="handleSave()"
  >
    <!-- name -->
    <va-text-input
      source="name"
      v-model="sectionData.name"
      required
    ></va-text-input>
    <va-save-button></va-save-button>
  </va-form>
</template>

<script>
import http from '../../plugins/axios';

export default {
  props: ['id', 'item'],

  data() {
    return {
      sectionData: {},
    };
  },

  computed: {},

  watch: {
    id() {
      this.loadSectionData();
    },
  },

  async created() {
    this.loadSectionData();
  },

  methods: {
    async loadSectionData() {
      const response = await http.get(`/sections/${this.id}`);
      this.sectionData = response.data.message.data;
    },

    async handleSave() {
      const updatedData = this.sectionData;

      this.$admin.http.put(`/sections/${this.id}`, updatedData).then(() => {
        this.$emit('saved');
      });
    },
  },
};
</script>
