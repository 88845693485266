import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

import en from 'vuetify/es5/locale/en';
import ru from 'vuetify/es5/locale/ru';

Vue.use(Vuetify);

let locale = 'ru';
if (localStorage.getItem('locale') !== null) {
  locale = localStorage.getItem('locale');
} else {
  locale = process.env.VUE_APP_I18N_LOCALE;
}

export default new Vuetify({
  lang: {
    locales: { en, ru },
    current: locale,
  },
});
