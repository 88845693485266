<template>
  <va-form
    :id="id"
    :item="item"
    disable-redirect
    @saved="$emit('saved')"
  >
    <va-text-input
      source="title_kz"
      required
    ></va-text-input>
    <va-text-input
      source="title_ru"
      required
    ></va-text-input>
    <va-text-input
      source="description_kz"
      required
    ></va-text-input>
    <va-text-input
      source="description_ru"
      required
    ></va-text-input>
    <va-number-input
      source="sale"
      required
    ></va-number-input>
    <va-number-input
      source="price"
      required
    ></va-number-input>
    <va-date-input
      v-model="expirationDate"
      source="expires_at"
      format="short"
    ></va-date-input>
    <va-file-input
      source="img"
      clearable
      preview
    ></va-file-input>
    <va-save-button></va-save-button>
  </va-form>
</template>

<script>
export default {
  props: ['id', 'item'],
  data() {
    return {
      expirationDate: Date.now(),
    };
  },
};
</script>
