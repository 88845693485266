<template>
  <va-show :item="item">
    <va-field source="name"></va-field>
    <va-field source="barcode"></va-field>
    <va-field source="count"></va-field>
  </va-show>
</template>

<script>
export default {
  props: ['item'],
};
</script>
