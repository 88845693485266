/* eslint-disable no-unused-vars */

import {
  LOGIN,
  LOGOUT,
  CHECK_AUTH,
  CHECK_ERROR,
  GET_NAME,
  GET_EMAIL,
  GET_PERMISSIONS,
} from 'vuetify-admin/src/providers/auth/actions';

let user = {};
export default (httpClient) => {
  return {
    [LOGIN]: async ({ username, password }) => {
      try {
        const response = await httpClient.post('/auth/login', {
          email: username,
          password,
        });

        user = response.data.data;
        localStorage.setItem('token', response.data.data.token);

        return Promise.resolve({ data: user });
      } catch (error) {
        return Promise.reject(error);
      }
    },
    [LOGOUT]: () => {
      localStorage.removeItem('token');
      user = {};
    },
    [CHECK_AUTH]: () => {
      return localStorage.getItem('token')
        ? Promise.resolve({
            data: user,
          })
        : Promise.reject();
    },
    [CHECK_ERROR]: (error) => Promise.resolve(),
    [GET_NAME]: (u) => u.name,
    [GET_EMAIL]: (u) => u.email,
    [GET_PERMISSIONS]: (u) => u.permissions,
  };
};
