<template>
  <va-show :item="item">
    <va-field source="goodName"></va-field>
    <va-image-field
      source="img_url"
      src="img_url"
    ></va-image-field>
    <va-field source="barcode"></va-field>
    <va-field source="brand"></va-field>
    <va-field source="price.price"></va-field>
    <va-date-field
      source="created_date"
      format="short"
    ></va-date-field>
  </va-show>
</template>

<script>
export default {
  props: ['item'],
  mounted() {},
};
</script>
