<template>
  <div>
    <!-- data -->
    <va-form
      :id="id"
      :item="item"
      disable-redirect
      @saved="handleSave()"
    >
      <!-- name -->
      <va-text-input
        source="name"
        v-model="userData.name"
        required
      ></va-text-input>

      <!-- surname -->
      <va-text-input
        source="surname"
        v-model="userData.surname"
      ></va-text-input>

      <!-- email -->
      <va-text-input
        source="email"
        v-model="userData.email"
        required
      ></va-text-input>

      <!-- phone -->
      <va-text-input
        source="phone"
        v-model="userData.phone"
        required
      ></va-text-input>

      <!-- gender -->
      <va-select-input
        source="gender"
        v-model="gender"
      ></va-select-input>

      <!-- birth_date -->
      <va-date-input
        v-model="getBirthDate"
        source="birth_date"
        format="short"
      ></va-date-input>
      <va-save-button></va-save-button>
    </va-form>

    <!-- password -->
    <va-form
      :id="id"
      :item="item"
      disable-redirect
      @saved="handleChangePassword()"
      class="user-edit__password-form"
    >
      <h3>Изменить пароль</h3>
      <!-- password -->
      <va-text-input
        source="password"
        v-model="password"
        required
      ></va-text-input>
      <va-save-button></va-save-button>
    </va-form>
  </div>
</template>

<script>
import http from '../../plugins/axios';
import millisecondsToDate, { dateToMilliseconds } from '../../helpers/dateConverter';

export default {
  props: ['id', 'item'],

  data() {
    return {
      userData: {},
      isNotificationsEnabled: false,
      password: null,
      gender: null,
      email: null
    };
  },

  computed: {
    getBirthDate: {
      get: function () {
        return this.userData.birth_date
          ? dateToMilliseconds(this.userData.birth_date)
          : Date.now();
      },
      set: function (newValue) {
        this.userData.birth_date = millisecondsToDate(newValue);
      },
    },
  },

  watch: {
    id() {
      this.loadUserData();
    },
  },

  async created() {
    this.loadUserData();
  },

  methods: {
    async loadUserData() {
      const response = await http.get(`/user/${this.id}`);
      this.userData = response.data.data;
    },

    async handleSave() {
      const updatedData = (({ name, surname, email, phone, birth_date }) => ({
        name,
        surname,
        email,
        phone,
        birth_date,
      }))(this.userData);

      updatedData['gender'] = parseInt(this.gender);

      this.$admin.http.put(`/user/${this.id}`, updatedData).then(() => {
        this.$emit('saved');
      });
    },

    async handleChangePassword() {
      this.$admin.http
        .post(`/user/change-password?user_id=${this.id}`, { password: this.password })
        .then(() => {
          this.$emit('saved');
        });
    },
  },
};
</script>

<style
  lang="scss"
  scoped
>
.user-edit {
  &__password-form {
    margin-top: 50px;
  }
}
</style>
