<template>
  <va-form
    :id="id"
    :item="item"
    disable-redirect
    @saved="$emit('saved')"
  >
    <!-- title_kz -->
    <va-text-input
      source="title_kz"
      required
    ></va-text-input>

    <!-- title_ru -->
    <va-text-input
      source="title_ru"
      required
    ></va-text-input>

    <!-- description_kz -->
    <va-text-input
      source="description_kz"
      required
    ></va-text-input>

    <!-- description_ru -->
    <va-text-input
      source="description_ru"
      required
    ></va-text-input>

    <!-- is_temporary -->
    <va-boolean-input
      source="is_temporary"
      v-model="isTemporary"
    ></va-boolean-input>

    <!-- start_date -->
    <va-date-input
      v-if="isTemporary"
      :value="startDate"
      source="start_date"
      format="short"
    ></va-date-input>

    <!-- end_date -->
    <va-date-input
      v-if="isTemporary"
      v-model="endDate"
      source="end_date"
      format="short"
    ></va-date-input>

    <!-- img -->
    <va-file-input
      source="img"
      clearable
      preview
      required
    ></va-file-input>
    <va-save-button></va-save-button>
  </va-form>
</template>

<script>
export default {
  props: ['id', 'item'],
  data() {
    return {
      isTemporary: false,
      startDate: Date.now(),
      endDate: Date.now() + 604800, // 1 week
    };
  },
  computed: {},
  methods: {},
};
</script>
