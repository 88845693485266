<template>
  <div>
    <va-aside-layout :title="asideTitle">
      <!-- show -->
      <birthday-coupons-show
        v-if="show"
        :item="item"
      ></birthday-coupons-show>

      <!-- edit -->
      <birthday-coupons-edit
        v-if="edit"
        :id="id"
        :item="item"
        @saved="onSaved"
      ></birthday-coupons-edit>

      <!-- create -->
      <birthday-coupons-form
        v-else
        :id="id"
        :item="item"
        @saved="onSaved"
      ></birthday-coupons-form>
    </va-aside-layout>

    <!-- list -->
    <base-material-card
      :icon="resource.icon"
      :title="title"
      color="blue"
    >
      <va-list
        ref="birthdayCouponsList"
        disable-create-redirect
        disable-global-search
        @action="onAction"
      >
        <va-data-table
          :fields="fields"
          disable-edit-redirect
          @item-action="onAction"
        >
          <template v-slot:[`field.img`]="{ value }">
            <div class="table-image-wrapper">
              <img
                :src="value"
                alt=" image"
              />
            </div>
          </template>
        </va-data-table>
      </va-list>
    </base-material-card>
  </div>
</template>

<script>
export default {
  components: {},
  props: ['resource', 'title'],
  data() {
    return {
      fields: [
        'img',
        { source: 'title_kz', sortable: true },
        { source: 'title_ru', sortable: false },
        { source: 'description_ru', sortable: false },
        { source: 'description_kz', sortable: false },
      ],
      asideTitle: null,
      id: null,
      item: null,
      show: false,
      edit: false,
    };
  },
  methods: {
    async onAction({ action, title, id, item }) {
      this.asideTitle = title;
      this.id = id;
      this.show = action === 'show';
      this.edit = action === 'edit';
      this.item = item;
    },
    onSaved() {
      this.asideTitle = null;
      this.$refs.birthdayCouponsList.fetchData();
    },
  },
  mounted() {},
};
</script>

<style
  lang="sass"
  scoped
></style>
