<template>
  <div>
    <va-aside-layout :title="asideTitle">
      <hot-show
        v-if="actionType === 'show'"
        :item="item"
      ></hot-show>

      <hot-import v-if="actionType === 'import'"></hot-import>

      <hot-form
        v-if="actionType === 'create'"
        :id="id"
        :item="item"
        :cities="cities"
        @saved="onSaved"
      ></hot-form>
    </va-aside-layout>

    <base-material-card
      :icon="resource.icon"
      :title="title"
      color="orange"
    >
      <va-list
        ref="hotList"
        disable-create-redirect
        disable-global-search
        @action="onAction"
      >
        <!-- custom import button -->
        <template v-slot:actions>
          <custom-import-button @action="onAction"></custom-import-button>
        </template>

        <!-- shop selects -->
        <v-container class="hot-list__selects-wrapper">
          <v-row justify="start">
            <v-col
              cols="12"
              sm="12"
              lg="6"
            >
              <v-select
                @change="handleCityChange"
                class="hot-list__select"
                :items="selectCities"
                item-text="title"
                item-value="value"
                menu-props="auto"
                :label="$t('interface.city')"
                hide-details
                prepend-icon="mdi-city-variant-outline"
                dense
                outlined
              ></v-select>
            </v-col>
            <v-col
              cols="12"
              sm="12"
              lg="6"
            >
              <v-select
                @change="handleShopChange"
                class="hot-list__select"
                :items="selectShops"
                item-text="title"
                item-value="value"
                menu-props="auto"
                :label="$t('interface.shop')"
                hide-details
                prepend-icon="mdi-store"
                dense
                outlined
              ></v-select>
            </v-col>
          </v-row>
        </v-container>

        <!-- list table -->
        <va-data-table
          :fields="fields"
          disableSelect
          disableDelete
          @item-action="onAction"
        >
        </va-data-table>
      </va-list>
    </base-material-card>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import { actionTypes } from '@/store/app';

export default {
  components: {},

  props: ['resource', 'title'],

  data() {
    return {
      fields: [
        { source: 'goodName', sortable: true },
        { source: 'barcode', sortable: true },
        { source: 'brand' },
      ],
      selectCities: [],
      selectShops: [],
      asideTitle: null,
      id: null,
      item: null,
      actionType: null,
    };
  },

  computed: {
    ...mapState({
      cities: (state) => state.app.cities,
    }),
  },

  async mounted() {
    localStorage.removeItem('hot');
    await this.loadCities();
    this.selectCities = this.cities.map((item) => ({
      value: item.id,
      title: item.title,
    }));
  },

  methods: {
    ...mapActions({
      loadCities: actionTypes.LOAD_CITIES,
    }),
    async onAction({ action, title, id, item }) {
      this.asideTitle = title;
      this.actionType = action;
      this.id = id;
      this.item = item;

      if (action === 'create' || action === 'import') {
        this.item = null;
        this.id = null;
      }
    },
    onSaved() {
      this.asideTitle = null;
      this.$refs.hotList.fetchData();
    },
    async handleCityChange(value) {
      const city = this.cities.find((item) => item.id === value);
      if (city) {
        this.selectShops = city.shops.map((item) => ({
          value: item.shop_id,
          title: item.name,
        }));
      }
    },
    async handleShopChange(value) {
      localStorage.setItem(
        'hot',
        JSON.stringify({
          type: 'fetch',
          payload: {
            shopId: value,
          },
        })
      );
      this.$refs.hotList.fetchData();
    },
  },
};
</script>

<style
  lang="sass"
  scoped
>
.hot-list
  &__selects-wrapper
    margin: 0
  &__select
    padding-top: 0
</style>
